<template>
  <div>
    <van-list class="zt-list">
      <div
        class="zt-item"
        v-for="item in list"
        :key="item.type_id"
        @click="toSubjectList(item)"
      >
        <div class="zt-cover"><img :src="item.image" /></div>
        <label class="zt-title">{{ item.type_name }}</label>
      </div>
    </van-list>
  </div>
</template>

<script>
import { fetchTypes } from "@/network/API";
export default {
  data() {
    return {
      list: [],
      isMore: false,
      title: this.$store.state.navTitle,
    };
  },
  async created() {
    let data = await fetchTypes();
    this.list = data;
  },
  methods: {
    toSubjectList(item) {
      this.$store.commit("showHeader", {
        showBar: true,
      });
      this.$router.push({
        path: "/list",
        query: {
          tid: item.type_id,
          name: item.type_name,
        },
      });
    },
  },
};
</script>

<style></style>
